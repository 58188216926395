<template>
    <div class="web-series">
        <PageTitle :title="title" />
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="filters"  v-if="!loading">
            <p>Select Series:</p>
            <multiselect v-model="currentTag" placeholder="Select status" :showLabels="false" :options="tagOptions" track-by="label" label="label" :searchable="false" :allow-empty="false">
                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
            </multiselect>
        </div>
        <div class="videos-container" v-if="!loading">
            <div v-for="(videoData, i) in filteredData" :key="i" class="video-container">
                <div class="info-section">
                    <h2>
                        {{videoData.Title}}
                    </h2>
                    <div class="tag">
                        {{videoData.Tag | startCase}}
                    </div>
                </div>
                <!-- <div v-if="videoData.VimeoID" class='embed-container'>
                    <iframe :src="'https://player.vimeo.com/video/'+ property.VimeoID" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div> -->
                <div v-if="videoData.VideoID" class='youtube-embed-container'>
                    <iframe :src="'https://www.youtube.com/embed/'+ videoData.VideoID" frameborder='0' allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from "../components/PageTitle"
import Spinner from 'vue-simple-spinner'
import Multiselect from 'vue-multiselect'

export default {
    name: "WebSeries",
    components: {
        PageTitle,
        Spinner,
        Multiselect
    },
    data(){
        return{
            title: "Web Series",
            data: [],
            loading: true,
            tagOptions: [
                {label: 'ALL VIDEOS', value: 'allVideos'},
                {label: 'BUYER', value: 'buyers'},
                {label: 'Expired To Sold', value: 'expiredToSold'},
                {label: 'COVID-19', value: 'COVID19'},
                {label: 'SELLER', value: 'sellers'}
            ],
            currentTag:  {label: 'All Videos', value: 'allVideos'},
        }
    },
    computed: {
        filteredData:function(){
            if(this.currentTag.value === 'allVideos'){
                return this.data;
            } else {
                return this.data.filter((item) => {
                    return item.Tag === this.currentTag.value
                })
            }
        },
    },
    metaInfo: {
        title: 'Web Series',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description', 
            content: "Paul Salazar"
        }]
    },
    mounted(){
        this.axios.get('https://strapi.uptowncreative.io/web-series')
        .then((response) => {
            this.data = response.data;
            this.loading = false;
        })
    }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}
		
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-embed-container{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.youtube-embed-container iframe,
.youtube-embed-container object,
.youtube-embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videos-container{
    margin: 2rem 4rem;
}

.video-container{
    padding-bottom: 4.5rem;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    &:last-child{
        border: none;
    }
}

.info-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.75rem 0;
    h2{
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 100;
        color: #fff;
    }
    .tag{
        font-weight: 500;
        padding: 2px 8px;
        font-size: 12px;
    }
}
@media (max-width: $mobile) { 
    .filters{
       padding: 2rem;
    }
    .videos-container{
        margin: 2rem 0;
    }
    .info-section{
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 2rem;
        flex-direction: column;
    }
}
</style>

<style lang="scss">
.filters{
    display: flex;
    justify-content: flex-end;
    padding: 2rem 4rem;
    background: #1a1a1a;
    align-items: center;
    p{
        margin: 0;
        margin-right: 30px;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 100;
        opacity: 0.25;
    }
}

.multiselect{
  &__tags{
    background: #1a1a1a !important;
  }
  &__single{
    color: #fff;
    background: #1a1a1a !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 2px;
  }
}

.multiselect{
    background: #1a1a1a;
    width: 200px
}
.multiselect__tags{
    background: #1a1a1a;
    border-radius: 1px;
    border: 1px solid rgba(255,255,255,0.3);
}
.multiselect__option{
    letter-spacing: 2px;
    text-transform: uppercase;
}
.multiselect__option--selected.multiselect__option--highlight{
    background: #1a1a1a !important;
}
.multiselect__option--highlight{
    background:#1a1a1a !important;
}
.multiselect__current, .multiselect__select{
    line-height: unset;
}
</style>
